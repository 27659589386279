
import barba from '@barba/core';
import Swiper from 'swiper';

export default class{

	constructor(){

        barba.hooks.beforeOnce((data) => {
            this.heroSlider()
            this.gallerySlider()
            this.commonSlider()
        })
        barba.hooks.after((data) => {
            this.heroSlider()
            this.gallerySlider()
            this.commonSlider()
        });
        
    }
    
    heroSlider(){
        new Swiper ('.l-hero__slider',{
            loop: true,
            effect :'fade',
            speed:3000,
            allowTouchMove :false,
            simulateTouch:false,
            autoplay: {
                delay: 4000
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            }
        })
    }
    gallerySlider(){
        new Swiper ('.p-index__gallery--slider',{
            slidesPerView: 2,
            freeMode: true,
            scrollbar: {
                el: ".swiper-scrollbar.--gallery",
                draggable: true,
            },
            spaceBetween: 20,
            breakpoints: {
                576: {
                    slidesPerView: 2.8,
                },
                768: {
                    slidesPerView: 3,
                },
                992: {
                    slidesPerView: 3.2,
                },
                1500: {
                    slidesPerView: 4.2,
                }
            }
        })
    }
    commonSlider(){
        const common_thumbnails = document.querySelectorAll('.common-slider-thumbnails a');
        const commonSlider = new Swiper('.common-slider', {
            navigation:  {
                nextEl: '.common-slider .swiper-button-next',
                prevEl: '.common-slider .swiper-button-prev',
            },
            on: {
                transitionStart(){
                    var	self = this;
                    for(var i = 0; i <  common_thumbnails.length; i++){
                        common_thumbnails[i].classList.remove('active');
                        common_thumbnails[self.realIndex].classList.add('active');
                    }
                }
            },
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
            },
            loop : true
        });
        
        for(var i = 0; i <  common_thumbnails.length; i++){
            commonThumbnailAddEvent( common_thumbnails[i],i);
        }
        function commonThumbnailAddEvent(el,i){
            el.addEventListener('click',function(e){
                e.preventDefault();
                commonSlider.slideTo(i+1);
            })
        }
    }
}