
import barba from '@barba/core';

export default class{

	constructor(){
        
        barba.hooks.enter((data) => {
            this.run()
        })
        
    }
    
    run(){
        const panoWraps = document.querySelectorAll('.wpvr-cardboard')
        if(panoWraps.length){
            panoWraps.forEach(panoWrap => {
                const script = panoWrap.nextElementSibling
                let str = script.innerText
                // let regex = /jQuery\(document\)\.ready\(function\(\) \{/i;
                
                // str = str.replace(regex,"")
                // regex = /\}\);$/i;
                // str = str.replace(regex,"")

                const addScript = document.createElement('script')
                addScript.textContent = str
                addScript.setAttribute('data-add-vr-script','') 
                const addVRScript = document.querySelector('[data-add-vr-script]')
                
                if(addVRScript){
                    addVRScript.remove()
                }
                document.body.appendChild(addScript)

                
            })
            // const Container = document.querySelector(".pnlm-render-container")
            // if(!Container){
            //     location.href = location.href
            // }
        }
    }
}