import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.indexConcept(data.next.container)
            this.indexFlow(data.next.container)
            this.scrollTrigger(data.next.container)
            // this.sideTrigger(data.next.container)
            this.flowTrigger(data.next.container)
        });
        barba.hooks.after((data) => {
            this.indexConcept(data.next.container)
            this.indexFlow(data.next.container)
            this.scrollTrigger(data.next.container)
            // this.sideTrigger(data.next.container)
            this.flowTrigger(data.next.container)
        });
    }
    indexConcept(container){
        this.conceptRing01 = gsap.utils.toArray(".concept-ring01");
        if(this.conceptRing01){
            if(this.conceptRing01) {
                gsap.to(this.conceptRing01, {
                    y: '80px',
                    scrollTrigger: {
                        trigger: this.conceptRing01,
                        start: 'top bottom',
                        end: 'bottom top',  
                        scrub: .4, 
                        toggleClass: {targets: this.conceptRing01, className: "active"},
                        // markers: true,
                    }
                });
            }
        }
        this.conceptRing02 = gsap.utils.toArray(".concept-ring02");
        if(this.conceptRing02){
            if(this.conceptRing02) {
                gsap.to(this.conceptRing02, {
                    y: '-80px',
                    scrollTrigger: {
                        trigger: this.conceptRing02,
                        start: 'top bottom',
                        end: 'bottom top',  
                        scrub: .4, 
                        toggleClass: {targets: this.conceptRing02, className: "active"},
                        // markers: true,
                    }
                });
            }
        }
    }
    indexFlow(container){
        this.flowRing01 = gsap.utils.toArray(".flow-ring01");
        if(this.flowRing01){
            if(this.flowRing01) {
                gsap.to(this.flowRing01, {
                    y: '80px',
                    scrollTrigger: {
                        trigger: this.flowRing01,
                        start: 'top bottom',
                        end: 'bottom top',  
                        scrub: .4, 
                        toggleClass: {targets: this.flowRing01, className: "active"},
                        // markers: true,
                    }
                });
            }
        }
        this.flowTree01 = gsap.utils.toArray(".flow-tree01");
        if(this.flowTree01){
            if(this.trigger) {
                gsap.to(this.flowTree01, {
                    y: '-80px',
                    scrollTrigger: {
                        trigger: this.flowTree01,
                        start: 'top bottom',
                        end: 'bottom top',  
                        scrub: .4, 
                        // markers: true,
                    }
                });
            }
        }
        this.flowTree02 = gsap.utils.toArray(".flow-tree02");
        if(this.flowTree02){
            if(this.flowTree02) {
                gsap.to(this.flowTree02, {
                    y: '-80px',
                    scrollTrigger: {
                        trigger: this.flowTree02,
                        start: 'top bottom',
                        end: 'bottom top',  
                        scrub: .4, 
                        // markers: true,
                    }
                });
            }
        }
    }
    scrollTrigger(container){
        
        this.scrollFade = gsap.utils.toArray(".js-scroll-fade");

        if(this.scrollFade) {
            this.scrollFade.forEach((el, i) => {

                let dataY = (el.getAttribute('data-y')) ? el.getAttribute('data-y') : 8;
                let dataX = (el.getAttribute('data-x')) ? el.getAttribute('data-x') : 0;

                gsap.set(el, {
                    autoAlpha: 0,
                    y:dataY,
                    x:dataX,
                });

                gsap.to(el, {
                    autoAlpha: 1,
                    duration: .8,
                    y:0,
                    x:0,
                    // ease: "circ.in",
                    scrollTrigger: {
                        trigger: el,
                        start: "top bottom-=60",
                        // toggleActions: "play reverse play reverse",
                        // markers: true,
                    }
                });
            })
        }

        this.scrollStroke = gsap.utils.toArray(".js-scroll-stroke");

        if(this.scrollStroke) {
            this.scrollStroke.forEach((el, i) => {

                let scrollType = (el.getAttribute('data-stroke')) ? el.getAttribute('data-stroke') : "start";
                
                let pr_str = el.textContent.split("");
                let str = "";
                pr_str.map( s => {
                    str += "<span>" + s + "</span>";
                })
                    
                el.innerHTML = str;
                el.spans = el.querySelectorAll('span');

                gsap.set(el.spans, {
                    autoAlpha: 0,
                });

                gsap.to(el.spans, {
                    autoAlpha: 1,
                    scrollTrigger: {
                        trigger: el,                        
                        start: "top bottom-=60",
                        // end: "center center-=100",
                        // markers: true,
                    },
                    stagger: {
                        from: scrollType,
                        amount: .8
                    }
                });
            })
        }

        this.scrollParallaxWrap = gsap.utils.toArray(".js-parallax-wrapper");
        this.scrollParallaxItem = gsap.utils.toArray(".js-parallax-item");

        if(this.scrollParallaxWrap) {
            if(this.scrollParallaxItem) {
                this.scrollParallaxWrap.forEach((wrap, i) => {
                    this.scrollParallaxItem.forEach((el, i) => {

                        let dataYset = (el.getAttribute('data-y-set')) ? el.getAttribute('data-y-set') : 0;
                        let dataXset = (el.getAttribute('data-x-set')) ? el.getAttribute('data-x-set') : 0;

                        let dataYto = (el.getAttribute('data-y-to')) ? el.getAttribute('data-y-to') : 0;
                        let dataXto = (el.getAttribute('data-x-to')) ? el.getAttribute('data-x-to') : 0;

                        let speed = (el.getAttribute('data-speed')) ? el.getAttribute('data-speed') : 0;
                            
                        gsap.set(el, {
                            // autoAlpha: 0,
                            y:dataYset,
                            x:dataXset,
                        });

                        gsap.to(el, {
                            autoAlpha: 1,
                            y:dataYto,
                            x:dataXto,
                            // ease: "expo.inOut",
                            scrollTrigger: {
                                trigger: wrap,
                                start: "center bottom",
                                end: "center top",
                                scrub: .5,
                                // markers: true,
                            }
                        });
                    })
                })
            }
        }
    }
    sideTrigger(container){
        this.sideBar = gsap.utils.toArray("#l-side");
        if(this.sideBar){
            if(this.sideBar) {
                gsap.to(this.sideBar, {
                    scrollTrigger: {
                        trigger: this.sideBar,
                        endTrigger:'.js-sidebar__trigger',
                        start: 'bottom 100%',
                        end: 'bottom 100%',
                        pin: true,  
                        // markers: true
                    }
                });
            }
        }
    }
    flowTrigger(container){
        this.flowSideBar = gsap.utils.toArray(".p-flow__sidebar");
        if(this.flowSideBar&&(window.innerWidth > 991)){
            if(this.flowSideBar) {
                gsap.to(this.flowSideBar, {
                    scrollTrigger: {
                        trigger: '.js-flow__trigger',
                        // endTrigger:'.js-flow__trigger',
                        // scroller:'.js-flow__trigger',
                        start: 'top 120px',
                        end: 'bottom 100%',
                        pin: this.flowSideBar,  
                        // markers: true
                    }
                });
            }
        }
    }
}